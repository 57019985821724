/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.15 : usayama) // 実装
*/
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import classNames from "classnames"
import { IoTrashOutline } from 'react-icons/io5'
// conf, action
import { APP_ROUTE } from '@config/routers'
import cartActions from '@redux/cart/actions'
// components
import Label from '@components/Typo/Label'
import LabelHtml from '@components/Typo/LabelHtml'
import LinkButton from '@components/UIKit/LinkButton'
import Button from '@components/UIKit/Button'
import InputSelect from '@components/Form/InputSelect'
// containers
// helper
import { calcPriceInTax, calcTotalItemPriceInTax, calcTotalPriceInTax } from '@lib/appHelpers'
// style, img, elm
const iconImg = require("@assets/images/title-icon-cart.png")
// const
const { getCartItems, setCartItem, removeCartItem } = cartActions
let numeral = require('numeral')

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
*/
export default function CartContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.15 : usayama) // 実装
   */
  const dispatch = useDispatch()

  /** 
   * state : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)
  const { cartItems } = useSelector(state => state.Cart)

  /** 
   * componentDidMount
   * https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.15 : usayama) // 実装
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(getCartItems())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * handle : 
   * 
   * @version 0.1 (2021.06.15 : usayama)
   */
  const handleOnChangeNum = (e, item) => dispatch(setCartItem({...item, 'selectedNum' : e.target.value}))
  const handleOnRemoveCartItem = (item) => dispatch(removeCartItem(item))

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.15 : usayama) // 実装
   * @version 0.3 (2021.06.22 : usayama) // text-nowrap 追加
   */
  return (
  <div className="cart cart-items">
    <div className="container">
      <div className="title title-h-line">
        <h3><Label label="title.cart" /></h3>
        <div className="h-line">
          <span></span>
          <img src={iconImg.default} alt="" />
          <span></span>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th><Label label="label.cart_item" /></th>
            <th><Label label="label.cart_item_name" /></th>
            <th className="text-nowrap"><Label label="label.cart_num" /></th>
            <th><Label label="label.cart_price" /></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {
          cartItems && (Object.keys(cartItems).length > 0) ? 
            Object.keys(cartItems).map((key, i) => {
              const item = cartItems[key]
              return <tr key={'item-' + i}>
                      <td>{ item.item_images && <img className="mr-3" src={item.item_images[0]} alt="" /> }</td>
                      <td>
                        <div className="item-name">{item.name}</div>
                        <div className="item-price">
                          <Label 
                            label="label.cart_item_price_in_tax" 
                            replaces={{'price' : numeral(calcPriceInTax(item.price_no_tax, item.tax)).format('0,0')}}
                          />
                        </div>
                      </td>
                      <td className="text-nowrap">
                        <InputSelect 
                          name="order_num"
                          className="mt-3 form-select-num" 
                          defaultValue={item.selectedNum}
                          options={item.order_num_options}
                          onChange={(e) => handleOnChangeNum(e, item)}
                        /> 
                      </td>
                      <td className="text-nowrap">
                        <div className="item-price">
                          <Label 
                            label="label.cart_item_price_in_tax_total" 
                            replaces={{'price' : numeral(calcTotalItemPriceInTax(item.price_no_tax, item.tax, item.selectedNum)).format('0,0')}}
                          />
                        </div>
                      </td>
                      <td>
                        <Button onClick={() => handleOnRemoveCartItem(item)} color="outline-danger" size="sm" node={<IoTrashOutline size={18} />} />
                      </td>
                    </tr>
            })
           : <tr><td colSpan={5} className="p-3"><Label label="text.no_cart_item" /></td></tr>
        }
        </tbody>
      </table>
      {
        cartItems && (Object.keys(cartItems).length > 0) && 
        <div className="total">
          <LabelHtml 
            label="label.cart_items_price_in_tax_total" 
            replaces={{'price' : numeral(calcTotalPriceInTax(cartItems)).format('0,0')}}
          />
        </div>
      }
    </div>
    <div className="cart container cart-action">
      <div className="form-action">
      { cartItems && (Object.keys(cartItems).length > 0) && <LinkButton to={APP_ROUTE.cart_form} color="success" size="lg" label="label.cart_to_form" /> }
        <LinkButton to={APP_ROUTE.items} color="outline-secondary" size="lg" label="label.cart_to_items" />
      </div>
    </div>
  </div>
  )
}
