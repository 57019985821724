/**
 * import
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 * @version 0.2 (2021.06.15 : usayama) // router 追加
 * @version 0.3 (2021.06.28 : usayama) // Seo 追加
 */
import React from 'react'
import { Router } from '@reach/router'
import { useTranslation } from 'react-i18next'
// conf, action
// components
import Seo from '@components/Tools/Seo'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import MypointContainer from '@containers/MypointContainer'
import CartProgressStepContainer from '@containers/CartProgressStepContainer'
import CartContainer from '@containers/CartContainer'
import CartFormContainer from '@containers/CartFormContainer'
import CartConfirmContainer from '@containers/CartConfirmContainer'
import CartThanksContainer from '@containers/CartThanksContainer'
// helpers
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 * @version 0.2 (2021.06.15 : usayama) // router 追加
 */
// const Cart = ({location}) => (
const Cart = ({location}) => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.28 : usayama_dx)
   */
  const { t } = useTranslation()

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13, 05.17 : usayama)
   * @version 0.2 (2021.06.28 : usayama) // Seo 追加
   */
	return <LayoutContainer>
    <Seo title={t('title.cart')} />
		<MypointContainer />
		<CartProgressStepContainer location={location} />
    <Router basepath='/cart'>
      <CartFormContainer path='/form' />
      <CartConfirmContainer path='/confirm' />
      <CartThanksContainer path='/thanks' />
      <CartContainer path='/' />
    </Router>
	</LayoutContainer>
}

/**
 * export
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 */
export default Cart
