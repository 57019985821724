/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.15 : usayama) // 実装
*/
import React from 'react'
// import { HiHome } from "react-icons/hi"
// conf, action
// components
import Label from '@components/Typo/Label'
// containers
// helpers
import { getPaths } from '@lib/helpers'
import { getCartStepFromPath } from '@lib/appHelpers'
// style, img, elm
// const

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
*/
export default function CartProgressStep(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.15 : usayama)
   */
  const { location } = props

  /** 
   * state : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)
  const [isStepPath, setIsStepPath] = React.useState(1)

  /** 
   * useEffect
   * 
   * @version 0.1 (2021.06.15 : usayama)
   */
  React.useEffect(() => {
    const path = getPaths(location.pathname)
    path[2] ? setIsStepPath(getCartStepFromPath(path[2])) : setIsStepPath(1)
  }, [location, setIsStepPath])

  /** 
   * componentDidMount
   * https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.05.13 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      setInitPage(true)
    }
  }, [initPage])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.15 : usayama) // 実装
   */
  return (
    <div className="cart container wrap mb-4">
      <div className="progress-step-bar">
        <ul>
          <li className={ isStepPath >= 1 && "done" }><span>1</span><Label label="label.cart_step_1" /></li>
          <li className={ isStepPath >= 2 && "done" }><span>2</span><Label label="label.cart_step_2" /></li>
          <li className={ isStepPath >= 3 && "done" }><span>3</span><Label label="label.cart_step_3" /></li>
          <li className={ isStepPath >= 4 && "done" }><span>4</span><Label label="label.cart_step_4" /></li>
        </ul>
      </div>
    </div>
  )
}
