/** 
 * import : 
 * 
 * @version 0.1 (2021.01.27 : usayama_dx)
 * @version 0.2 (2021.02.22 : usayama_dx) // useTranslation 追加
 * @version 0.3 (2021.05.28 : usayama_dx) // リファクタリング
*/
import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
import { useTranslation } from 'react-i18next'
// conf, action
// components
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.01.27 : usayama_dx)
*/
const Input = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.01.27 : usayama_dx)
   * @version 0.2 (2021.02.15 : usayama_dx) // suffix 追加
   * @version 0.3 (2021.02.22 : usayama_dx) // inputRef, useTranslation 追加
   * @version 0.4 (2021.04.26 : usayama_dx) // register 追加, is-invalid 追加
   * @version 0.5 (2021.06.03 : usayama_dx) // register 削除
   * @version 0.6 (2021.06.03 : usayama_dx) // labelKey 追加
   */
  const { t } = useTranslation()
  const { className,
          labelClassName,
          labelKey,
          size,
          id,
          name,
          type,
          label,
          suffix,
          placeholder,
          helpText,
          readonly,
          feedback,
          inputRef,
          disabled, 
          errors,  ...attrs } = props
  const classes = classNames(
    "form-group",
    "mb-3",
    className,
    errors && "has-danger"
  )
  const inputClasses = classNames(
    "form-control",
    size && "form-control-" + size,
    feedback && "is-" + feedback,
    errors && "form-control-danger",
    errors && "is-invalid"
  )
  const labelClasses = classNames(
    "mb-2",
    labelClassName,
  )

  /** 
   * return : 
   * 
   * @version 0.1 (2021.01.27 : usayama_dx)
   * @version 0.2 (2021.02.15 : usayama_dx) // suffix 追加
   * @version 0.3 (2021.02.22 : usayama_dx) // inputRef 追加
   * @version 0.4 (2021.02.25 : usayama_dx) // placeholder 変更
   * @version 0.5 (2021.04.26 : usayama_dx) // label, placeholder, helpText label 変更
   * @version 0.6 (2021.04.26 : usayama_dx) // register 追加, errors.message 追加
   * @version 0.7 (2021.05.28 : usayama_dx) // リファクタリング、 Label 削除、labelClasses 追加
   * @version 0.8 (2021.06.03 : usayama_dx) // {...register} 削除, ref 追加
   * @version 0.9 (2021.06.03 : usayama_dx) // labelKey 追加
   */
  return (
    <div className={classes}>
      { label && <label className={labelClasses}>{t('label.' + label)}</label>}
      <input 
        type={type} 
        id={id}
        name={name} 
        className={inputClasses} 
        placeholder={placeholder && t('placeholder.' + placeholder)}
        readOnly={readonly}
        disabled={disabled}
        ref={inputRef}
        {...attrs}
       />
      { suffix && <div className="ml-2">{t('label.' + suffix)}</div> } 
      { helpText && <small className="form-text text-muted">{t(helpText)}</small> }
      { errors && <div className="form-text text-danger">{t('message.error.' + labelKey + '.' + name)}</div> }
      { feedback && <div className={feedback.status + "-feedback"}>{t(feedback.text)}</div> }
    </div>
  )
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.01.27 : usayama_dx)
 * @version 0.2 (2021.02.15 : usayama_dx) // suffix 追加
 * @version 0.3 (2021.02.22 : usayama_dx) // inputRef 追加
 * @version 0.4 (2021.04.26 : usayama_dx) // register 追加
 * @version 0.5 (2021.05.28 : usayama_dx) // labelClasses 追加
 * @version 0.6 (2021.06.03 : usayama_dx) // register 削除
 * @version 0.7 (2021.06.03 : usayama_dx) // labelKey 追加
*/
Input.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  labelKey: PropTypes.string,
  size: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  suffix: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  errors: PropTypes.object,
  feedback: PropTypes.object,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.func,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.01.27 : usayama_dx)
 * @version 0.2 (2021.02.15 : usayama_dx) // suffix 追加
 * @version 0.3 (2021.02.22 : usayama_dx) // inputRef 追加
 * @version 0.4 (2021.04.26 : usayama_dx) // register 追加
 * @version 0.5 (2021.05.28 : usayama_dx) // labelClasses 追加
 * @version 0.6 (2021.06.03 : usayama_dx) // register 削除
 * @version 0.7 (2021.06.03 : usayama_dx) // labelKey 追加
*/
Input.defaultProps = {
  className: "",
  labelClassName: "",
  labelKey: "",
  size: null,
  id: null,
  name: "",
  type: "text",
  label: null,
  suffix: null,
  placeholder: null,
  helpText: null,
  errors: null,
  feedback: null,
  readonly: false,
  disabled: false,
  inputRef: null,
}

/** 
 * export
 * 
 * @version 0.1 (2021.01.27 : usayama_dx)
*/
export default Input
