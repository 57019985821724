/**
 * import
 *
 * @version 0.1 (2020.06.29 : usayama)
 */
// import React from 'react'
// conf, action
// components
// conf, action
// consts

/**
 * reduxModal 表示
 * 
 * @version 0.1 (2021.05.18 : usayama)
 */
export function showAlertMessage(message = null) {
//	const m = t('message.error.signup')
  	alert(message)
  	window.location.reload()
}

/**
 * step 番号取得
 * 
 * @version 0.1 (2021.05.18 : usayama)
 */
export function getCartStepFromPath(str = null) {
    let num = 1;
    switch(str) {
        case 'form':
            num = 2
            break;
        case 'confirm':
            num = 3
            break;
        case 'thanks':
            num = 4
            break;
        default:
            num = 1
    }
    return num
}

/**
 * カート計算
 * 
 * @version 0.1 (2021.06.14 : usayama)
 */
export function calcPriceInTax(price = null, tax = null) {
    return price + Math.floor(price * tax / 100)
}

/**
 * カート計算
 * 
 * @version 0.1 (2021.06.15 : usayama)
 */
export function calcTotalItemPriceInTax(price = null, tax = null, num = null) {
    return (price + Math.floor(price * tax / 100)) * num
}

/**
 * カート計算
 * 
 * @version 0.1 (2021.06.15 : usayama)
 */
export function calcTotalPriceInTax(cartItems = {}) {
    let total = 0
    if(cartItems && (Object.keys(cartItems).length > 0)) {
        total = Object.keys(cartItems).map((key, i) => {
            const item = cartItems[key]
            total = total + (item.price_no_tax + Math.floor(item.price_no_tax * item.tax / 100)) * parseInt(item.selectedNum)
            return total
        })
    } 
    return total.slice(-1)[0]
}
