/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
*/
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
// import classNames from "classnames"
import { Beforeunload } from 'react-beforeunload'
// conf, action
import { APP_ROUTE } from '@config/routers'
import { address_state } from '@config/app.config'
import { VALIDATION_PATTERNS } from '@config/validation.patterns'
import cartFormActions from '@redux/cartForm/actions'
import toolsActions from '@redux/tools/actions'
// components
import AlertWrap from '@components/UIKit/AlertWrap'
import LinkButton from '@components/UIKit/LinkButton'
import Button from '@components/UIKit/Button'
import Input from '@components/Form/Input'
import InputSelect from '@components/Form/InputSelect'
import Label from '@components/Typo/Label'
// containers
// helper
import { isEmpty } from '@lib/helpers'
// style, img, elm
// const
const { getCartForm, setCartForm } = cartFormActions
const { getAddress } = toolsActions

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
*/
export default function CartFormContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.15 : usayama)
   * @version 0.2 (2021.06.22 : usayama) // me 追加
   */
  const dispatch = useDispatch()
  const { cartItems } = useSelector(state => state.Cart)
  const { cartForm } = useSelector(state => state.CartForm)
  const { address } = useSelector(state => state.Tools)
  const { me } = useSelector(state => state.Auth)
  const { handleSubmit, register, formState, setValue, errors } = useForm({ mode: 'onChange' })

  /** 
   * state : 
   * 
   * @version 0.1 (2021.06.15 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)

  /** 
   * handle : 
   * 
   * @version 0.1 (2021.06.15 : usayama)
   */
  const handleOnSubmit = (d) => {
    dispatch(setCartForm(d))
    navigate(APP_ROUTE.cart_confirm)
  }
  const handleZipCode = (e) => { 
    if(e.target.value && e.target.value.length === 7) {
      dispatch(getAddress({'zip_code': e.target.value}))
    }
  }

  /** 
   * useEffect : 
   * 
   * @version 0.1 (2021.06.15 : usayama)
   */
  React.useEffect(() => {
    if(address) {
      setValue('address_state', address.pref,  { shouldValidate: true })
      setValue('address_city', address.address,  { shouldValidate: true })
    }
  }, [address, setValue])


  /** 
   * useEffect : 
   * 
   * @version 0.1 (2021.06.22 : usayama)
   */
  React.useEffect(() => {
    if(me && me.email) {
      setValue('email', me.email,  { shouldValidate: true })
    }
  }, [me, setValue])

  /** 
   * componentDidMount
   * https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.15 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      (cartItems && (Object.keys(cartItems).length > 0)) ? setInitPage(true) : navigate(APP_ROUTE.cart)
      dispatch(getCartForm())
    }
  }, [initPage, dispatch, cartItems])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.15 : usayama)
   * @version 0.2 (2021.06.30 : usayama) // className= に修正
   */
  return (
    <div className="">
      <Beforeunload onBeforeunload={event => event.preventDefault()} />
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="container wrap mt-2">
          <div className="mt-2 mb-4 text-center">
              <h2 className="h2 font-weight-bold">
                  <Label label="title.cart_form" />
              </h2>
          </div>
          <div className="cart cart-form container mb-4">
            <div className="card">
              <div className="card">
                <div className="card-body">
                  <Input
                    labelKey="cart"
                    label="name"
                    placeholder="cart.name"
                    name="name"
                    defaultValue={cartForm && cartForm.name}
                    errors={errors && errors.name}
                    className={errors.name && "is-invalid"}
                    labelClassName="required"
                    inputRef={register({ required: true })}
                  />
                  <Input
                    labelKey="cart"
                    label="zip_code"
                    placeholder="cart.zip_code"
                    name="zip_code"
                    maxLength={7}
                    defaultValue={cartForm && cartForm.zip_code}
                    errors={errors && errors.zip_code}
                    className={ errors.zip_code && "is-invalid"}
                    labelClassName="required"
                    onChange={handleZipCode}
                    inputRef={register({ required: true, pattern: VALIDATION_PATTERNS.zip })}
                  />
                  <InputSelect
                    labelKey="cart"
                    options={address_state}
                    label="address_state"
                    name="address_state"
                    defaultValue={cartForm && cartForm.address_state}
                    errors={errors && errors.address_state}
                    className={errors.address_state && "is-invalid"}
                    labelClassName="required"
                    inputRef={register({ required: true })}
                  />
                  <Input
                    labelKey="cart"
                    label="address_city"
                    placeholder="cart.address_city"
                    name="address_city"
                    defaultValue={cartForm && cartForm.address_city}
                    errors={errors && errors.address_city}
                    className={errors.address_city && "is-invalid"}
                    labelClassName="required"
                    inputRef={register({ required: true })}
                  />
                  <Input
                    labelKey="cart"
                    label="address_street"
                    placeholder="cart.address_street"
                    name="address_street"
                    defaultValue={cartForm && cartForm.address_street}
                    errors={errors && errors.address_street}
                    className={errors.address_street && "is-invalid"}
                    labelClassName="required"
                    inputRef={register({ required: true })}
                  />
                  <Input
                    labelKey="cart"
                    label="tel"
                    placeholder="cart.tel"
                    name="tel"
                    defaultValue={cartForm && cartForm.tel}
                    errors={errors && errors.tel}
                    className={errors.tel && "is-invalid"}
                    labelClassName="required"
                    inputRef={register({ required: true, pattern: VALIDATION_PATTERNS.tel })}
                  />
                  <Input
                    labelKey="cart"
                    label="email"
                    placeholder="cart.email"
                    name="email"
                    defaultValue={cartForm && cartForm.email}
                    errors={errors && errors.email}
                    className={errors.email && "is-invalid"}
                    labelClassName="required"
                    readonly={me && !isEmpty(me.email)}
                    inputRef={register({ required: true, pattern: VALIDATION_PATTERNS.email })}
                  />
                  <AlertWrap labelHtml="text.cart_email" color="outline-success" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container wrap mt-2">
          <div className="cart container cart-action">
            <div className="form-action">
              <Button type="submit" color="success" label="cart_to_confirm" size="lg" disabled={!formState.isValid} />
              <LinkButton to={APP_ROUTE.cart} color="outline-info" size="lg" label="label.cart_to_back" />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
