/** 
 * import : 
 * 
 * @version 0.1 (2021.05.27 : usayama_dx)
*/
import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import PropTypes from 'prop-types'
// conf, action
// components
import Label from '@components/Typo/Label'
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.27 : usayama_dx)
*/
const LinkButton = props => {
  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.27 : usayama_dx)
   * @version 0.2 (2021.06.15 : usayama_dx) // props 項目追加
   */
  const { className,
          color,
          label,
          size,
          block,
          disabled,
          to, ...attrs } = props
  const classes = classNames(
    "btn",
    color && "btn-" + color,
    size && "btn-" + size,
    block && "btn-block",
    className,
  )

  /** 
   * return : 
   * 
     * @version 0.1 (2021.05.27 : usayama_dx)
   */
  return <Link to={to} className={classes} {...attrs}><Label label={label} /></Link>
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.05.27 : usayama_dx)
 * @version 0.2 (2021.06.15 : usayama_dx) // 項目追加
*/
LinkButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  to: PropTypes.string,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.05.27 : usayama_dx)
 * @version 0.2 (2021.06.15 : usayama_dx) // 項目追加
*/
LinkButton.defaultProps = {
  className: '',
  color: "",
  label: '',
  size: null,
  block: false,
  disabled: false,
  to: '',
}

/** 
 * export
 * 
 * @version 0.1 (2021.05.27 : usayama_dx)
*/
export default LinkButton
