/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 * @version 0.2 (2021.06.23 : usayama) // isEmpty 追加
*/
import React from 'react'
import { useSelector } from 'react-redux'
// conf, action
import { APP_ROUTE } from '@config/routers'
// components
import Label from '@components/Typo/Label'
import LabelHtml from '@components/Typo/LabelHtml'
import Badge from '@components/UIKit/Badge'
import LinkButton from '@components/UIKit/LinkButton'
// containers
// helper
import { isEmpty } from '@lib/helpers'
// style, img, elm
// const
let numeral = require('numeral')

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13, 05.17 : usayama)
*/
export default function Mypoint(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.13, 05.17 : usayama)
   * @version 0.2 (2021.05.19 : usayama) // Account から User に変更
   * @version 0.3 (2021.06.22 : usayama) // me 追加
   */
  const { me } = useSelector(state => state.Auth)

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13, 05.17 : usayama)
   * @version 0.2 (2021.06.22 : usayama) // 導線切り替え、表示変更
   * @version 0.3 (2021.06.23 : usayama) // isEmpty 追加
   * @version 0.4 (2021.06.24 : usayama) // 文言 追加
   * @version 0.5 (2021.06.30 : usayama) // ポイントが 0 のときは表示しない, my_point_total 追加
   * @version 0.6 (2021.07.26 : usayama) // コメントアウト
   */
  return (
    <div className="_mypoint">
    {/*
      !isEmpty(me) ? 
      <div className="points">
        <Badge className="me-3" color="danger" label="label.my_points_coin" />
        { 
          me.my_points && 
          <span className="point">
            {
              (me.my_points.tsunaichi_point > 0) &&
                <LabelHtml 
                  className="me-3" 
                  label="label.my_tsunaichi_point" 
                  replaces={{'point': numeral(me.my_points.tsunaichi_point).format('0,0')}}
                />
            }
            {
              me.my_points.shop_points.map((shop_point, i) => {
                return (shop_point.point > 0) ? 
                    <LabelHtml
                      key={'my_shop_point' + i} 
                      className="me-3" 
                      label="label.my_shop_point" 
                      replaces={{'shop_name' : shop_point.shop_name, 'point': numeral(shop_point.point).format('0,0')}}
                    />
                  : ''
              })
            }
            { (me.my_point_total <= 0) && <Label label="label.my_point_total_zero" />}
          </span>
        }
      </div>
      : <div className="to_signup">
          <strong className="text-danger d-block pb-1 pt-3" style={{'line-height': 1}}><Label label="text.head_pr_text" /></strong>
          <LinkButton to={APP_ROUTE.signup} label="text.to_signup" />
        </div>
    */}
    </div>
  )
}

