/** 
 * import : 
 * 
 * @version 0.1 (2021.01.26 : usayama)
 * @version 0.2 (2021.06.15 : usayama) // Alert から派生
*/
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// conf, action
// components
import Label from '@components/Typo/Label'
import LabelHtml from '@components/Typo/LabelHtml'
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.01.26 : usayama)
 * @version 0.2 (2021.06.15 : usayama) // Alert から派生
*/
const AlertWrap = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.01.26 : usayama)
   * @version 0.2 (2021.06.15 : usayama) // Alert から派生
   */
  const { className,
          icon,
          color,
          label,
          labelHtml,
          heading,
          status,
          text, ...attrs } = props
  const classes = classNames(
    "alert",
    color && "alert-" + color,
    status && "alert-" + status,
    className,
  )

  /** 
   * return : 
   * 
   * @version 0.1 (2021.01.26 : usayama)
   * @version 0.2 (2021.06.15 : usayama) // Alert から派生
   */
  return <div className={classes} {...attrs}>
          {heading && <h4 className="alert-heading">{heading}</h4>}
          {icon}
          {text}
          {label && <p><Label label={label} /></p> }
          {labelHtml && <p><LabelHtml label={labelHtml} /></p> }
        </div>
}

/** 
 * propTypes
 * 
 * @author k watanabe
 * @version 0.1 (2021.01.26 : usayama)
 * @version 0.2 (2021.06.15 : usayama) // Alert から派生
*/
AlertWrap.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  label: PropTypes.string,
  labelHtml: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node,
  heading: PropTypes.string,
  text: PropTypes.node,
}

/** 
 * defaultProps
 * 
 * @author k watanabe
 * @version 0.1 (2021.01.26 : usayama)
 * @version 0.2 (2021.06.15 : usayama) // Alert から派生
*/
AlertWrap.defaultProps = {
  className: "",
  status: "",
  label: "",
  labelHtml: "",
  color: "",
  icon: null,
  heading: null,
  text: null,
}

/** 
 * export
 * 
 * @author k watanabe
 * @version 0.1 (2021.01.26 : usayama)
 * @version 0.2 (2021.06.15 : usayama) // Alert から派生
*/
export default AlertWrap
