/** 
 * import : 
 * 
 * @version 0.1 (2021.06.15 : usayama)
*/
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
// import classNames from "classnames"
// import { HiHome } from "react-icons/hi"
// conf, action
import { APP_ROUTE } from '@config/routers'
import paymentActions from '@redux/payment/actions'
// components
import StripeCheckoutForm from '@components/Tools/StripeCheckoutForm'
import LinkButton from '@components/UIKit/LinkButton'
import Button from '@components/UIKit/Button'
import Label from '@components/Typo/Label'
import LabelHtml from '@components/Typo/LabelHtml'
// containers
// helpers
import { isEmpty } from '@lib/helpers'
import { calcPriceInTax, calcTotalItemPriceInTax, calcTotalPriceInTax } from '@lib/appHelpers'
const { initGetStripeSession, getStripeSession, initSetPayment, openPayment } = paymentActions
// style, img, elm
// const
let numeral = require('numeral')

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.15 : usayama)
*/
export default function CartConfirmContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.16 : usayama)
   */
  const dispatch = useDispatch()

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.15 : usayama)
   * @version 0.2 (2021.06.16 : usayama) // clientSecret 追加
   */
  const { cartItems } = useSelector(state => state.Cart)
  const { cartForm } = useSelector(state => state.CartForm)
  const { isOpenPayment, stripeSession } = useSelector(state => state.Payment)

  /** 
   * state : 
   * 
   * @version 0.1 (2021.06.15 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)

  /** 
   * event : 
   * 
   * @version 0.1 (2021.06.15 : usayama)
   */
  const handleOnSubmit = (e) => dispatch(openPayment())

  /** 
   * componentDidMount
   * https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.15 : usayama)
   * @version 0.2 (2021.06.17 : usayama) // initSetPayment 追加
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(initGetStripeSession())
      dispatch(initSetPayment())
      if(!(Object.keys(cartItems).length > 0) || !(Object.keys(cartItems).length > 0)) {
        navigate(APP_ROUTE.cart)
      } else {
        dispatch(getStripeSession(cartItems))
        setInitPage(true)
      }
    }
  }, [initPage, cartItems, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.15 : usayama)
   * @version 0.2 (2021.06.16 : usayama)
   */
  return (
    <div className="">
    { isOpenPayment && <StripeCheckoutForm /> }
    {
      initPage && <div>
        <div className="container wrap">
          <div className="mt-2 mb-4 text-center">
            <h2 className="h2 font-weight-bold">
              <Label label="title.cart_confirm" />
            </h2>
          </div>
          <div className="cart-confirm container mb-4">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <div className="font-weight-bold"><Label label="label.name" /></div>
                  { cartForm.name }
                </div>
                <div className="form-group">
                  <div className="font-weight-bold"><Label label="label.address" /></div>
                  <Label label="label.zip" />{ cartForm.zip_code } { cartForm.address_state }{ cartForm.address_city }{ cartForm.address_street }
                </div>
                <div className="form-group">
                  <div className="font-weight-bold"><Label label="label.tel" /></div>
                  { cartForm.tel }
                </div>
                <div className="form-group">
                  <div className="font-weight-bold"><Label label="label.email" /></div>
                  { cartForm.email }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container wrap mt-2">
          <div className="mt-2 mb-4 text-center">
            <h2 className="h2 font-weight-bold">
              <Label label="title.cart_order_confirm" />
            </h2>
          </div>
          <div className="cart cart-form cart-confirm cart-confirm-total container mb-4">
            <div className="card">
              <div className="card-body">
                {
                  (!isEmpty(cartItems)) && Object.keys(cartItems).map((key, i) => {
                    const item = cartItems[key]
                    return <div className="cart-items">
                      <div className="cart-item">
                        <img className="mr-3" src={item.item_images[0]} style={{ 'height': 75}} alt="" />
                        <div className="cart-item-detail">
                          <h3>{item.name}</h3>
                          <p>
                            <span className="me-2">
                              <Label label="label.cart_item_price" /> : 
                              <strong>
                                <Label 
                                  label="label.cart_item_price_in_tax" 
                                  replaces={{'price' : numeral(calcPriceInTax(item.price_no_tax, item.tax)).format('0,0')}}
                                />
                              </strong>
                            </span>
                            <span className="me-2">
                              <Label label="label.order_num" /> : 
                              <strong>{item.selectedNum}</strong>
                            </span>
                            <span className="me-2">
                              <Label label="label.cart_item_price_in_tax_total" /> : 
                              <strong>
                                <Label 
                                  label="label.cart_item_price_in_tax" 
                                  replaces={{'price' : numeral(calcTotalItemPriceInTax(item.price_no_tax, item.tax, item.selectedNum)).format('0,0')}}
                                />
                              </strong>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  })
                }
                <dl>
                  <dt><Label label="label.cart_price" /></dt>
                  <dd>
                    <LabelHtml 
                      label="label.cart_items_price_in_tax_total_price" 
                      replaces={{'price' : numeral(calcTotalPriceInTax(cartItems)).format('0,0')}}
                    />
                  </dd>
                </dl>
                <dl>
                  <dt><Label label="label.shipping_fee" /></dt>
                  <dd>￥0</dd>
                </dl>
                <dl className="total">
                  <dt><Label label="label.cart_total_price" /></dt>
                  <dd>
                    <LabelHtml 
                      label="label.cart_items_price_in_tax_total_confirm" 
                      replaces={{'price' : numeral(calcTotalPriceInTax(cartItems)).format('0,0')}}
                    />
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="cart container cart-action">
            <div className="form-action">
              { !isEmpty(stripeSession) && <Button onClick={handleOnSubmit} color="success" label="cart_to_submit" size="lg" /> }
              <LinkButton to={APP_ROUTE.cart_form} color="outline-info" size="lg" label="label.cart_to_back_form" />
            </div>
          </div>
        </div>
      </div>
    }
    </div>
  )
}
