/** 
 * import : 
 * 
 * @version 0.1 (2021.02.09 - 02.10 : usayama)
 * @version 0.2 (2021.05.28 : usayama_dx) // リファクタリング
*/
import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
import { useTranslation } from 'react-i18next'
// conf, action
// components
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.02.09 - 02.10 : usayama)
*/
const InputSelect = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.02.09 - 02.10 : usayama)
   * @version 0.2 (2021.03.22 : usayama_dx) // inputRef 追加
   * @version 0.3 (2021.05.27 : usayama) // status, name追加
   * @version 0.4 (2021.05.28 : usayama_dx) // labelClassName 追加
   */
  const { t } = useTranslation()
  const { className,
          labelClassName,
          labelKey,
          size,
          id,
          name,
          options,
          optionLabelKey,
          placeholder,
          label,
          helpText,
          errors,
          feedback,
          readonly,
          inputRef,
          disabled, 
          status,...attrs } = props
  const classes = classNames(
    "form-group",
    "mb-3",
    className,
    status,
    errors && "has-danger"
  )
  const selectClasses = classNames(
    "form-control",
    status,
    feedback && "is-" + feedback,
    errors && "form-control-danger",
    errors && "is-invalid"
  )
  const labelClasses = classNames(
    "mb-2",
    labelClassName,
  )

  /** 
   * return : 
   * 
   * @version 0.1 (2021.02.09 - 02.10 : usayama)
   * @version 0.2 (2021.03.22 : usayama_dx) // attrs, inputRef 追加
   * @version 0.3 (2021.05.28 : usayama_dx) // リファクタリング
   * @version 0.4 (2021.06.03 : usayama_dx) // labelKey 追加
   */
  return (
    <div className={classes}>
      { label && <label className={labelClasses}>{t('label.' + label)}</label>}
      <select className={selectClasses} name={name} ref={inputRef} {...attrs}>
      { placeholder && <option value="">{t(placeholder)}</option>}
      {
        Object.keys(options).map((key, i) => {
          return <option key={key} value={key}>
                  { optionLabelKey ? t(optionLabelKey + '.' + options[key]) : options[key] }
                 </option>
        })
      }
      </select>
      { helpText && <small className="form-text text-muted">{t(helpText)}</small> }
      { errors && <div className="form-text text-danger">{t('message.error.' + labelKey + '.' + name)}</div> }
      { feedback && <div className={feedback.status + "-feedback"}>{t(feedback.text)}</div> }
    </div>
  )
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.02.09 - 02.10 : usayama)
 * @version 0.2 (2021.03.22 : usayama_dx : usayama) // inputRef 追加
 * @version 0.2 (2021.05.27 : usayama) // status 追加
 * @version 0.4 (2021.05.28 : usayama_dx) // labelClasses 追加, object を object に変更
 * @version 0.5 (2021.06.03 : usayama_dx) // labelKey 追加
*/
InputSelect.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  labelKey: PropTypes.string,
  size: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  status: PropTypes.string,
  options: PropTypes.object,
  optionLabelKey: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  errors: PropTypes.object,
  feedback: PropTypes.object,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.func,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.02.09 - 02.10 : usayama)
 * @version 0.2 (2021.03.22 : usayama_dx) // inputRef 追加
 * @version 0.3 (2021.05.27 : usayama) // status 追加
 * @version 0.4 (2021.05.28 : usayama_dx) // labelClasses 追加
 * @version 0.5 (2021.06.03 : usayama_dx) // labelKey 追加
*/
InputSelect.defaultProps = {
  className: "",
  labelClassName: "",
  labelKey: "",
  size: null,
  id: null,
  name: "",
  label: null,
  status: "",
  options: null,
  optionLabelKey: null,
  placeholder: null,
  helpText: null,
  errors: null,
  feedback: null,
  readonly: false,
  disabled: false,
  inputRef: null,
}

/** 
 * export
 * 
 * @version 0.1 (2021.02.09 - 02.10 : usayama)
*/
export default InputSelect
