/** 
 * import : 
 * 
 * @version 0.1 (2021.06.17 : usayama)
*/
import React from 'react'
import { useDispatch } from 'react-redux'
// conf, action
import { APP_ROUTE } from '@config/routers'
import cartActions from '@redux/cart/actions'
import cartFormActions from '@redux/cartForm/actions'
// components
import Label from '@components/Typo/Label'
import LabelHtml from '@components/Typo/LabelHtml'
import LinkButton from '@components/UIKit/LinkButton'
// containers
// helper
// style, img, elm
// const
const { removeCartItems } = cartActions
const { initGetCartForm } = cartFormActions

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.17 : usayama)
*/
export default function CartThanksContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.17 : usayama)
   */
  const dispatch = useDispatch()

  /** 
   * state : 
   * 
   * @version 0.1 (2021.06.17 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)

  /** 
   * componentDidMount
   * https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.17 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(removeCartItems())
      dispatch(initGetCartForm())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.17 : usayama)
   */
  return (
    <div className="">
      <div className="container wrap">
        <h2><Label label='title.cart_thanks' /></h2>
        <LabelHtml label='text.cart_thanks' />
        <div className="mt-5 text-center">
          <LinkButton to={APP_ROUTE.root} color="success" label="label.to_back" />
        </div>
      </div>
    </div>
  )
}
